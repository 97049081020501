import { Component } from 'vue-property-decorator'

import DiscountMixin from './mixins/discount'

import BillingCycleRadios from './BillingCycleRadios.vue'

@Component({
  components: {
    BillingCycleRadios
  }
})
export default class BillingCycleSwitch extends DiscountMixin {
  billingCycle = this.$route.params.currentCycle?.replace('ly', '') || 'month'

  changeCycle (cycle: string) {
    this.billingCycle = cycle

    this.$emit('billing-cycle-changed', this.billingCycle)
  }
}
