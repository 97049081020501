import { Component } from 'vue-property-decorator'
import { CommonBaseMixin } from './base'
import { Plan, Pro } from '../../areas/notLoggedIn/plan.interface'
import { bsConstants, getPermissions, HasIndex, newDate } from 'booksprout'

@Component
export default class DiscountMixin extends CommonBaseMixin {
  plans: Plan[] = []
  offerActive = false

  get isolateToPublisher () {
    return this.$route.query.p === '1'
  }

  get isFromLogin () {
    return this.$route.query.fl === '1'
  }

  get showDiscountedPrices () {
    return !!this.$route.query.showDiscountedPrices
  }

  get planKeys(): string[] {
    const planConstants = this.MODULE_CONSTANTS.SUBSCRIPTIONS.PLANS

    if (this.isolateToPublisher) {
      return [
        planConstants.PUBLISHER.KEY
      ]
    } else if (this.isFromLogin) {
      return [
        planConstants.BEST_SELLER.KEY,
        planConstants.PRO.KEY,
        planConstants.GROWING.KEY,
        planConstants.FREE.KEY,
      ]
    } else {
      return [
        planConstants.BEST_SELLER.KEY,
        planConstants.PRO.KEY,
        planConstants.GROWING.KEY,
        planConstants.PUBLISHER.KEY
      ]
    }
  }

  get permissionKeys () {
    // canViewReviewers, canHaveAutomatedArcDistribution and canHaveAutomatedArcReminders
    // are core permissions, they aren't listed in Author perms
    return [
      'maxRunningArcs', 'maxParticipantsPerArc', 'canViewReviewers', 'canHaveAutomatedArcDistribution',
      'canHaveAutomatedArcReminders', 'canCreateAudioBookArcs', 'canChooseDefaultReviewDisclaimer',
      'canHaveNeverEndingArcs', 'canLimitReviewersToTopReviewersOnly', 'canAddVirtualAssistants',
      'canFreeTypePenNames', 'canChooseBrandedDefaultReviewDisclaimer', 'canHaveTeamFollowers'
    ]
  }

  checkActiveOffer () {
    this.offerActive = newDate() < newDate(bsConstants.SUBSCRIPTIONS.DISCOUNTS.END_DATE)

    if (this.offerActive) {
      const timeoutAfter = newDate(bsConstants.SUBSCRIPTIONS.DISCOUNTS.END_DATE).getTime() - newDate().getTime()

      // When delay is larger than 2147483647 or less than 1, the delay will be set to 1. Non-integer delays are truncated to an integer.
      // https://nodejs.org/api/timers.html#timers_settimeout_callback_delay_args
      if (timeoutAfter < 2147483647) {
        setTimeout(() => {
          // update prices to remove discount
          this.plans = []
          this.offerActive = false
          this.setupPlans()
        }, timeoutAfter)
      }
    }
  }

  getPlan (planName: string) {
    switch (planName) {
      case bsConstants.SUBSCRIPTIONS.PLANS.FREE.KEY:
        return bsConstants.SUBSCRIPTIONS.PLANS.FREE
      case bsConstants.SUBSCRIPTIONS.PLANS.GROWING.KEY:
        return bsConstants.SUBSCRIPTIONS.PLANS.GROWING
      case bsConstants.SUBSCRIPTIONS.PLANS.PRO.KEY:
        return bsConstants.SUBSCRIPTIONS.PLANS.PRO
      case bsConstants.SUBSCRIPTIONS.PLANS.BEST_SELLER.KEY:
        return bsConstants.SUBSCRIPTIONS.PLANS.BEST_SELLER
      case bsConstants.SUBSCRIPTIONS.PLANS.PUBLISHER.KEY:
        return bsConstants.SUBSCRIPTIONS.PLANS.PUBLISHER
      default: // Won't ever get here - just here to satisfy typing
        return bsConstants.SUBSCRIPTIONS.PLANS.GROWING
    }
  }

  setupPlans () {
    this.planKeys.map((planKey: string) => {
      const monthlyPrice = this.getPlan(planKey).PRICES.MONTH
      const discountedMonthlyPrice = this.showDiscountedPrices && !this.offerActive
        ? this.minusSubDiscountNoDate(monthlyPrice)
        : this.minusSubDiscount(monthlyPrice)
      const yearlyPrice = this.getPlan(planKey).PRICES.YEAR
      const discountedYearlyPrice = this.showDiscountedPrices && !this.offerActive
        ? this.minusSubDiscountNoDate(yearlyPrice, 'year')
        : this.minusSubDiscount(yearlyPrice, 'year')
      const planPermissions = getPermissions(void 0, planKey).authorPerms // planKey is authorRole

      this.plans.push({
        key: planKey,
        title: this.$tc(`modules.subscriptions.plans.${planKey}.title`),
        subText: this.$tc(`modules.subscriptions.plans.${planKey}.subTitle`),
        monthlyPrice,
        discountedMonthlyPrice: Math.floor(discountedMonthlyPrice),
        yearlyPrice,
        discountedYearlyPrice: Math.floor(discountedYearlyPrice),
        pros: this.permissionKeys.map((permissionKey) => {
          const key = `modules.subscriptions.plans.features.${permissionKey}`
          const planPermission = (planPermissions as HasIndex)[permissionKey]
          let value: boolean | number = planPermission ?? true

          if (this.$te(key)) {
            const hintKey = `${key}Hint`
            const pro: Pro = {
              title: this.$tc(key),
              hint: this.$te(hintKey) ? this.$tc(hintKey) : void 0,
              value,
              mobileDisplayOnSingleLine: typeof planPermission === 'number' || permissionKey === 'canLimitReviewersToTopReviewersOnly',
              hasLongText: permissionKey === 'canLimitReviewersToTopReviewersOnly'
            }
            return pro
          }

          return void 0
        }).filter(pro => !!pro),
        bestValue: planKey === this.MODULE_CONSTANTS.SUBSCRIPTIONS.PLANS.BEST_SELLER.KEY
      })
    })
  }

  created () {
    this.checkActiveOffer()
    this.setupPlans()
  }
}
