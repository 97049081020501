var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.plan)?_c('div',{class:{
    'bs-w-author-plan': true,
    'bs-w-author-plan__best-value': _vm.plan.bestValue,
    'bs-w-author-plan__publisher': _vm.plan.key === 'publisher'
  }},[(_vm.plan.bestValue)?_c('div',{staticClass:"bs-w-a-p-b-v-tag"},[_c('strong',[_vm._v("BEST VALUE")]),_c('icon-star')],1):_vm._e(),_c('h2',{staticClass:"h3",class:{'bs-w-a-p-narrow-title': _vm.plan.key === 'pro'}},[_c('strong',[_vm._v(_vm._s(_vm.plan.title))])]),_c('p',[_vm._v("\n    "+_vm._s(_vm.plan.subText)+"\n  ")]),(_vm.showYearlyPrices)?_c('div',{staticClass:"bs-w-prices"},[_c('div',{staticClass:"bs-w-price"},[_c('strong',[_vm._v("$"+_vm._s(_vm.plan.discountedYearlyPrice))]),_c('span',[_vm._v("Per year")])]),(_vm.offerActive)?_c('div',{staticClass:"bs-w-original-price"},[_vm._v("\n      Regularly "),_c('strong',[_vm._v("$"+_vm._s(_vm.plan.yearlyPrice))])]):_vm._e()]):_c('div',{staticClass:"bs-w-prices"},[_c('div',{staticClass:"bs-w-price"},[_c('strong',[_vm._v("$"+_vm._s(_vm.plan.discountedMonthlyPrice))]),_c('span',[_vm._v("Per month")])]),(_vm.offerActive)?_c('div',{staticClass:"bs-w-original-price"},[_vm._v("\n      Regularly "),_c('strong',[_vm._v("$"+_vm._s(_vm.plan.monthlyPrice))])]):_vm._e()]),_c('website-button',{attrs:{"to":_vm.websitePaths.signUp,"use-router":false}},[_vm._v("\n    SELECT THIS PLAN\n  ")]),_c('ul',{staticClass:"bs-w-a-p-plan-details"},[(_vm.$q.screen.lt.md)?_c('li',[_c('button',{staticClass:"bs-w-a-p-plan-details-btn",attrs:{"type":"button","aria-controls":`${_vm.plan.key}Details`,"aria-expanded":_vm.detailsShowing ? 'true' : 'false'},on:{"click":function($event){_vm.detailsShowing = !_vm.detailsShowing}}},[_c('strong',[_vm._v("See details about this plan")]),_c('icon-arrow-down')],1)]):_vm._e(),(_vm.detailsShowing)?_c('li',[_c('ul',{attrs:{"id":`${_vm.plan.key}Details`}},_vm._l((_vm.plan.pros),function(pro){return _c('li',{key:pro.title,class:{
            'bs-w-a-p-p-d-split': !pro.mobileDisplayOnSingleLine,
            'bs-w-a-p-p-d-long-text': pro.hasLongText
          }},[(_vm.$q.screen.lt.lg)?_c('span',[_vm._v("\n            "+_vm._s(pro.title)+"\n          ")]):_vm._e(),(typeof pro.value === 'boolean' && pro.value === false)?_c('icon-excluded',{staticClass:"bs-w-excluded",attrs:{"aria-hidden":"true","role":"presentation"}}):_vm._e(),(typeof pro.value === 'boolean' && pro.value === true)?_c('icon-included',{staticClass:"bs-w-included",attrs:{"aria-hidden":"true","role":"presentation"}}):(typeof pro.value === 'number')?_c('strong',[_vm._v("\n            "+_vm._s(pro.value === -1 ? 'Unlimited' : pro.value)+"\n          ")]):_vm._e()],1)}),0)]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }