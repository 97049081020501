import { Component, Prop } from 'vue-property-decorator'
import { CommonBaseMixin } from './mixins/base'
import { bsConstants, newDate } from 'booksprout'

interface DisplayTime {
  tens: number | string
  ones: number | string
}

enum Period {
  Days,
  Hours,
  Minutes,
  Seconds
}

@Component
export default class Countdown extends CommonBaseMixin {
  @Prop({ type: Boolean }) readonly offerActive!: boolean

  offerInterval: any

  days: DisplayTime = {
    tens: '-',
    ones: '-'
  }
  hours: DisplayTime = {
    tens: '-',
    ones: '-'
  }
  minutes: DisplayTime = {
    tens: '-',
    ones: '-'
  }
  seconds: DisplayTime = {
    tens: '-',
    ones: '-'
  }

  displayTime (time: number): DisplayTime {
    const parts = [...time + ''].map(Number)
    return parts.length === 2 ? {
      tens: parts[0],
      ones: parts[1]
    } : {
      tens: 0,
      ones: parts[0]
    }
  }

  private timeLeft (timeLeft: number, period: Period): number {
    switch (period) {
      case Period.Days:
        return Math.floor(timeLeft / (1000 * 60 * 60 * 24))
      case Period.Hours:
        return Math.floor(timeLeft / (1000 * 60 * 60) % 24)
      case Period.Minutes:
        return Math.floor(timeLeft / (1000 * 60) % 60)
      case Period.Seconds:
      default:
        return Math.floor(timeLeft / 1000 % 60)
    }
  }

  mounted () {
    if (this.offerActive) {
      this.offerInterval = setInterval(() => {
        const now = newDate()
        const timeDiff = newDate(bsConstants.SUBSCRIPTIONS.DISCOUNTS.END_DATE).getTime() - now.getTime()

        // if 1s left, hide discount on next tick
        if (timeDiff > 1000) {
          this.days = this.displayTime(this.timeLeft(timeDiff, Period.Days))
          this.hours = this.displayTime(this.timeLeft(timeDiff, Period.Hours))
          this.minutes = this.displayTime(this.timeLeft(timeDiff, Period.Minutes))
          this.seconds = this.displayTime(this.timeLeft(timeDiff, Period.Seconds))
        } else {
          clearInterval(this.offerInterval)
          return
        }
      }, 1000)
    }
  }
}
