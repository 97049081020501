import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'

import { SET_META } from 'booksprout-app'
import DiscountMixin from 'booksprout-app/src/components/mixins/discount'

import { BaseMixin } from 'src/components/mixins/base'
import { Faq } from '../../faq.interface'

import IconArrowRight from '../../../components/svg-icons/IconArrowRight.vue'
import IconBook from '../../../components/svg-icons/IconBook.vue'
import IconPen from '../../../components/svg-icons/IconPen.vue'
import Offer from 'booksprout-app/src/components/Offer.vue'
import WebsiteAuthorPlan from '../../../components/WebsiteAuthorPlan.vue'
import BillingCycleSwitch from 'booksprout-app/src/components/BillingCycleSwitch.vue'
import WebsiteButton from '../../../components/WebsiteButton.vue'
import WebsiteFaq from '../../../components/WebsiteFaq.vue'
import WebsiteMember from '../../../components/WebsiteMember.vue'
import WebsiteTag from '../../../components/WebsiteTag.vue'

@Component({
  components: {
    IconArrowRight,
    IconBook,
    IconPen,
    BillingCycleSwitch,
    WebsiteAuthorPlan,
    WebsiteButton,
    WebsiteFaq,
    WebsiteMember,
    Offer,
    WebsiteTag
  }
})
export default class WebsitePlansPage extends mixins(BaseMixin, DiscountMixin) {
  faqs: Faq[] = []

  authorCount = 40000
  billingCycle = 'month'

  setBillingCycle (cycle: string) {
    this.billingCycle = cycle
  }

  mounted () {
    this.getWebsiteStats().then(stats => {
      this.authorCount = stats.authorCount
    })
  }

  created () {
    const title = 'Pricing'
    const canonicalUrl = window.location.origin + this.websitePaths.pricing

    void this.$store.dispatch(SET_META, {
      title: title,
      ogTitle: title,
      ogUrl: canonicalUrl,
      ogImage: window.location.origin + '/cdn/website/honest-reviews.png',
      canonicalUrl
    })

    this.faqs.push({
      question: 'How much do reviewer accounts cost?',
      answer: 'Reviewer accounts are always free!'
    }, {
      question: 'Do you offer a free trial?',
      answer: 'We do not offer a free trial. Instead, we provide a 30 day money back guarantee if you’re not satisfied with our services for any reason. So, pick the plan that’s best for you and if it doesn’t work out, just let us know!'
    }, {
      question: 'Do you guarantee a certain number of reviewers?',
      answer: 'Because every book is different, we cannot make any guarantees as to how many reviewers will participate in your review opportunity. Much like the large retailers that sell books, we see more interest in books that are on-market and well packaged. The more appealing your book is to reviewers, the more reviewers you’ll get!'
    }, {
      question: 'Do you guarantee that reviewers will write a review?',
      answer: 'We do everything we can to encourage reviews from everyone that participated in your review opportunity. This includes multiple follow-ups with all participants and manually taking action against problematic users. Because we are dealing with real people who sometimes have other obligations or priorities, we cannot guarantee a 100% review rate. In general, authors can expect about 8 reviews for every 10 participants.'
    }, {
      question: 'Can I change my subscription as needed?',
      answer: 'Yes, all of our plans are month-to-month so you can easily upgrade and downgrade whenever you need to.'
    }, {
      question: 'Do I have to give reviews to get reviews on Booksprout?',
      answer: 'No, you do not have to give reviews to get reviews on Booksprout. We designed our platform with the integrity of the review process in mind, adhering to guidelines set forth by various online book retail platforms. We believe in fostering a genuine community for authors and readers. On our platform, authors can manage their review process efficiently, while readers can discover new books and share honest opinions with no obligation to reciprocate reviews.\n' +
        '\n' +
        'By choosing Booksprout, you are joining a community that values the authenticity and credibility of book reviews. Our primary goal is to help authors effectively manage their review process, with the added benefit of getting their books into the hands of readers who will appreciate them. We also pride ourselves in helping readers find books they’ll love.'
    })
  }
}
