import { Component, Prop } from 'vue-property-decorator'
import { Plan } from 'booksprout-app/src/areas/notLoggedIn/plan.interface'

import { BaseMixin } from 'src/components/mixins/base'

import IconArrowDown from './svg-icons/IconArrowDown.vue'
import IconExcluded from './svg-icons/IconExcluded.vue'
import IconIncluded from './svg-icons/IconIncluded.vue'
import IconStar from './svg-icons/IconStar.vue'
import WebsiteButton from './WebsiteButton.vue'
import WebsiteOrSeparator from './WebsiteOrSeparator.vue'
import WebsiteTag from './WebsiteTag.vue'

@Component({
  components: {
    IconArrowDown,
    IconExcluded,
    IconIncluded,
    IconStar,
    WebsiteButton,
    WebsiteOrSeparator,
    WebsiteTag
  }
})
export default class WebsiteAuthorPlan extends BaseMixin {
  @Prop({ type: Object }) readonly plan!: Plan
  @Prop({ type: Boolean }) readonly offerActive!: boolean
  @Prop({ type: Boolean }) readonly showYearlyPrices!: boolean

  detailsShowing = false

  mounted () {
    this.detailsShowing = this.$q.screen.gt.sm
  }
}
