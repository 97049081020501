import { Component } from 'vue-property-decorator'
import { CommonBaseMixin } from './mixins/base'

@Component
export default class BillingCycleRadios extends CommonBaseMixin {
  cycle = this.$route.params.currentCycle?.replace('ly', '') || 'month'

  changeCycle () {
    this.cycle = this.cycle === 'year'
      ? 'month'
      : 'year'

    this.$emit('change-cycle', this.cycle)
  }
}
