import { Component, Prop } from 'vue-property-decorator'
import { CommonBaseMixin } from './mixins/base'
import Countdown from './Countdown.vue'

@Component({
  components: {
    Countdown
  }
})
export default class Offer extends CommonBaseMixin {
  @Prop({ type: Boolean }) readonly offerActive!: boolean
}
