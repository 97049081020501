import { Component, Prop } from 'vue-property-decorator'
import { BaseMixin } from 'src/components/mixins/base'

interface Faq {
  question: string
  answer: string
}

@Component
export default class WebsiteFaq extends BaseMixin {
  @Prop({ type: Object }) readonly faq!: Faq
}
